<template>
<section class="dashboard">
    <main-layout>
        <div class="row" v-if="visible">
            <div class="col-md-12">
                 <b-alert show variant="primary" >
                    <div class="alert-body">
                        <x-icon size="1.5x" class="pull-right close_button text-danger" @click="visible = !visible"></x-icon>
                        <span>Here you can add, edit all your students' details from this area. You can either add your students’ data one by one or you can upload/import a list of students in Microsoft Excel format. The list of all your existing students’ database will be displayed below and later you can add students into the respective intake list for the University submission.</span>
                    </div>
                </b-alert>
            </div>
        </div>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h5 class="content-header-title float-left mb-0" style="margin-top: 5px">All Students</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                <li class="breadcrumb-item active">Manage Students</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card bg-light-gray mb-1" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-2">
                        <treeselect v-model="search.course_level_id" :options="courseLevelList" id="course_level_id" placeholder="Select Course Level" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.country_id" :options="countryList" id="country_id" placeholder="Select Country" autocomplete="off"/>
                    </div>
                    <!-- <div class="col-md-2">
                        <treeselect v-model="search.submission_status_id" :options="statusTreeSlectList" id="submission_status_id" placeholder="Select status" autocomplete="off"/>
                    </div> -->
                    <div class="col-md-2">
                        <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Student ID">
                    </div>
                    <div class="col-md-1">
                        <input type="text" v-model="search.name" class="form-control" id="name" placeholder="First name">
                    </div>
                    <div class="col-md-1">
                        <input type="text" v-model="search.last_name" class="form-control" id="last_name" placeholder="Last name">
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.intake_id" :options="intakeTreeselectList" id="intake_id" placeholder="Select Intake" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="col">
                                <button class="btn btn-primary btn-block" @click="searchData()">Search</button>
                            </div>                            
                            <div class="col">
                                <!-- <button class="btn btn-primary btn-block" @click="searchData()"><i data-feather='search'></i> Search</button> -->
                                <button class="btn btn-outline-danger" @click="ClearSearchData()"><i data-feather='x'></i>Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card agency-list-card">
            <div class="card-body p-0">
                <div class="row p-2">
                    <div class="col-md-3">
                    </div>
                    <div class="col-md-9 text-right" v-show="inDeadline">

                        <b-dropdown class="mr-2" text="Export As" variant="outline-primary">
                            <b-dropdown-item download :href="download_url">
                                Excel                                            
                            </b-dropdown-item>
                        </b-dropdown>                        

                        <button v-if="studentNotAddIntakeCheck()" @click="addToIntakeAllStudent()" class="btn btn-success waves-effect waves-float waves-light mr-1">
                            Add All Students To Intake
                        </button>

                        <router-link class="btn btn-primary waves-effect waves-float waves-light mr-2" to="/add-student">
                            <i data-feather="plus-square"></i>
                            Add New
                        </router-link>

                        <button type="button" @click="toggle_filter()" class="btn btn-secondary waves-effect waves-float waves-light"><i data-feather='filter'></i></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-warning mt-1 alert-validation-msg" role="alert" v-show="!inDeadline">
                            <div class="alert-body">
                                <p class="text-center">You shall be allowed to submit your list for commission between <strong>{{ dDate(intakeItem.start_date) }}</strong> - <strong>{{ dDate(intakeItem.end_date) }}</strong>.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row mt-1 mb-2  pl-2 pr-2"  v-show="flag.show_filter">
                    <!-- <div class="col-md-3">
                        <flat-pickr
                        id="edit_end_date"
                        v-model="search.start_date"
                        :config="configs.flatpickr2"
                        class="form-control"
                        placeholder="Select start date"/>
                    </div> -->
                </div>

                
                <div class="row" v-if="this.editItem">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <sidebar-form 
                            :show_sidebar="flag.show_modal_form"
                            v-on:toggle-sidebar="toggle_modal_form()"
                            title="Edit Student"
                            >
                            <div class="mb-1">
                                <h5 class="">
                                    <span class="align-middle">Edit Student</span>
                                </h5>
                            </div>
                            <div class="flex-grow-1 wait_me_edit_form">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_intake_name"><b>First/Given Name</b> <span class="text-danger">*</span></label>
                                            <input type="text" v-model="editItem.first_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_intake_name"><b>Last/Family Name</b> <span class="text-danger">*</span></label>
                                            <input type="text" v-model="editItem.last_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                        </div>
                                    </div>
                                    <!-- <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_end_date"><b>Date of Birth</b></label>
                                                <flat-pickr
                                                    id="edit_end_date"
                                                    v-model="editItem.dob"
                                                    :config="configs.flatpickr"
                                                    class="form-control"
                                                    placeholder="Select end date"/>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-12">
                                        <div class="form-group">
                                            <label for="course_level_id"><b>Course Level</b></label>
                                            <treeselect v-model="editItem.course_level_id" :options="courseLevelList" placeholder="Select Course level" id="course_level_id" autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="course_title"><b>Course Title</b></label>
                                            <input type="text" v-model="editItem.subject" class="form-control" id="course_title" placeholder="Enter course title">
                                        </div>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="country_id"><b>Country</b></label>
                                            <treeselect v-model="editItem.country_id" :options="countryList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                                        <!-- <button type="button" @click="update_reset()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button> -->
                                    </div>
                                </div>
                            </div>
                        </sidebar-form>
                    </div>
                </div>

     

                <div class="table-responsive" v-show="inDeadline">
                    <table class="table table-hover" style="width:100%;">
                        <thead>
                            <tr>
                                <th class="">#</th>
                                <th>Student ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <!-- <th class="text-right">DOB</th> -->
                                <th class="text-center">Nationality</th>
                                <th class="text-center px-1">Start Date</th>
                                <th class="text-center">Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody v-if="studentList && studentList.length > 0">
                            <tr v-for="(item, index) in studentList" :key="index">
                                <td class="px-1">
                                    <span class="d-inline" v-html="index + pagination.slOffset"></span>
                                </td>
                                <td class="px-1">
                                    <strong v-tooltip="'Student ID'" v-html="item.uni_ref_no"></strong>
                                </td>
                                 <td v-html="item.first_name"></td>
                                <td v-html="item.last_name"></td>
                                <!-- <td class="text-right"><span v-if="item.dob" v-html="dDate(item.dob)"></span></td> -->
                                <td class="px-1">
                                    <div class="d-flex justify-content-center align-items-center cursor-pointer">
                                        <div v-if="item.country" @click="editData(item)" class="avatar-wrapper">
                                            <div class="avatar  mr-1" v-tooltip="item.country.name">
                                                <img :src="item.flag_url" alt="Avatar" height="32" width="32">
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <!-- <td class="px-1">
                                    <span class="text-truncate align-middle cursor-pointer" style="font-size: 13px">
                                        <span v-tooltip="item.subject" v-html="item.subject"></span>
                                    </span>
                                    <br />

                                    <small class="emp_post text-muted" v-html="cn(item, 'course_level.name', '-')">
                                    </small>
                                </td> -->
                                <!-- <td class="px-1"> 
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.tuition_fee)"></small>
                                </td> -->
                                <!-- <td class="px-1">
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.commission)"></small><br>
                                    <small class="emp_post text-muted text-truncate align-middle float-right" v-html="float2(item.commission_percentage)+'%'"></small><br>
                                </td> -->
                                <td class="text-center px-1">
                                    <!-- <span v-html="dMonth(item.start_date)"></span> -->
                                    <span v-html="dMonth(item.intake_month)"></span>
                                </td>
                                <td class="text-center">
                                    <small v-if="item.submission_status.name == '-'" v-html="item.submission_status.name"></small>
                                    <small v-else :class="'badge badge-pill badge-light-' + item.submission_status.color" v-html="item.submission_status.name"></small>
                                    <!-- <small class="d-block text-muted" v-html="dMonth(item.intake_month)"></small> -->
                                    <small v-if="cn(item, 'intake.intake_month', null)" class="d-block text-muted" v-html="dMonth(item.intake.intake_month)"></small>
                                </td>
                                <td>
                                    <div v-if="item.submission_status.code != 'as_submission_submitted'">
                                        <button type="button" class="btn btn-outline-primary btn-sm" v-tooltip="'Edit'" @click="editData(item)" v-if="item.submission_status.color !== 'success'" style="margin-right: 5px"><edit-icon size="1.5x" class="custom-class"></edit-icon></button>

                                        <button type="button" class="btn btn-outline-success btn-sm" v-tooltip="'Add to intake'" @click="addToIntake(item)" v-if="item.added_to_intake_at == null"><plus-square-icon size="1.5x" class="custom-class"></plus-square-icon></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="9" class="text-center">
                                    <span v-if="studentList === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no student data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    
                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination>
                </div>
            </div>
        </div>
    </main-layout>
</section>
</template>

<script>

import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
import { BPagination, BDropdown, BDropdownItem, BAlert } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../SidebarForm';
// import flatPickr from 'vue-flatpickr-component';
import Swal from 'sweetalert2';
import { PlusSquareIcon, EditIcon, XIcon } from 'vue-feather-icons';
import Hashids from 'hashids';
import moment from 'moment';

export default{
    name: 'ManageStudents',
    metaInfo: {
        titleTemplate: 'UCLan | Manage Students'
    },
    components:{
        MainLayout,
        BPagination,
        BDropdown,
        BDropdownItem,
        BAlert,
        XIcon,
        // InfoIcon,
        // BToast,
        Treeselect,
        SidebarForm,
        // flatPickr,
        // MoreVerticalIcon,
        PlusSquareIcon,
        EditIcon
    },
    data:()=>{
        return {
            visible: true,
            studentList: null,
            regionList: [],
            regionCountryList: [],
            statusTreeSlectList: [],
            statusList: [],
            inDeadline: true,
            intakeItem: {},
            flag:{
                show_agent_form:false,
                show_filter:false,
                show_modal_form:false
            },
            form:{
                send_invitation:{
                    checked_all: false,
                    agent_ids:[]
                },
                agent_status_update:{
                    agent_id:'',
                    new_status: ''
                }
            },
            search: {
                course_level_id: null,
                country_id: null,
                uni_ref_no: '',
                name: '',
                last_name: '',
                start_date: '',
                submission_status_id: null,
                intake_id: null,
            },
            list:{
                agent_statuses:[{
                    id: 'inactive',
                    label: 'Inactive',
                    btn_color: 'outline-danger',
                    text_color: 'text-danger'
                }, {
                    id: 'pending',
                    label: 'Pending',
                    btn_color: 'outline-warning',
                    text_color: 'text-warning'
                },{
                    id: 'approved',
                    label: 'Approved',
                    btn_color: 'outline-success',
                    text_color: 'text-success'
                }],
                intakes:[]
            },
            editItem: {},
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    maxDate: new Date()
                },        
                flatpickr2: {
                    dateFormat: 'd M Y',
                    mode: "range"
                }        
            },
            courseLevelList: [],
            courseTitleList: [],
            intakeTreeselectList: [],
            download_url:'#',
        };
    },
    watch: {
        // 'search.region_id': function (value) {
        //     // this.countryList = this.getCountryList(value)
        //     // this.getListData()
        // },
        'search.country_id': function () {
            // this.getListData()
        },
        'search.name': function () {
            // this.getListData()
        }
    },
    created: async function(){

        this.setup_urls();
        this.init_data();

        const countries = await this.countries();

        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        });

        this.gen_download_url();

    },
    mounted: function(){
        // this.checkIntakeInDeadline();
        
        this.getListData();
        this.getRegionList()
        this.getRegionCountryList()
        this.getUniversityIntakeList()
        this.getCourseLevelList()
        this.getStatusList()
        feather.replace();
    },
    methods:{
        studentNotAddIntakeCheck () {
            if (this.studentList) {
                const withoutIntakeStudents = this.studentList.filter(item => item.intake_id == null)
                if (withoutIntakeStudents.length) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
             
        },
        init_data: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url("api/v1/agent/ajax/manage_student_init_data");
            
            try{
                var res=await jq.get(url);
                ref.list.intakes=res.data.intakes
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        check_all_invitaion: function(check_all=false){

            var ref=this;
            var invitation_from=ref.form.send_invitation;

            if(check_all){

                if(invitation_from.checked_all){
                   ref.studentList.forEach(row=>{
                       invitation_from.agent_ids.push(row.id);
                   });
                }else  invitation_from.agent_ids=[];

            }else{
                
                if(invitation_from.agent_ids.length==ref.studentList.length){
                    invitation_from.checked_all=true;
                }else invitation_from.checked_all=false;
            }

        },
        ClearSearchData () {
            this.search = {
                course_level_id: null,
                country_id: null,
                uni_ref_no: '',
                name: '',
                last_name: '',
                start_date: '',
                submission_status_id: null,
                intake_id: null,
            }

            this.getListData();
        },
        async searchData () {
            this.getListData();
            this.gen_download_url();
        },
        toggle_agent_form: function(){
            this.flag.show_agent_form=!this.flag.show_agent_form;
        },
        addToIntakeAllStudent: function(){
            // const swalWithBootstrapButtons = Swal.mixin({
            //     customClass: {
            //         confirmButton: 'btn btn-success',
            //         cancelButton: 'btn btn-danger ml-2'
            //     },
            //     buttonsStyling: false
            // })

            // swalWithBootstrapButtons.fire({
            //     title: 'Add To Intake',
            //     html: "Are you sure do you want to add to intake all students?",
            //     icon: 'info',
            //     showCancelButton: true,
            //     confirmButtonText: 'Yes',
            //     cancelButtonText: 'No',
            //     reverseButtons: true
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         // this.addToIntakeAllStudentConfirm()
            //         const item = {
            //             id: 'all'
            //         }
            //         this.addToIntake(item)
            //     }
            // })

            const item = {
                        id: 'all'
                    }
            this.addToIntake(item)
        },
        addToIntakeAllStudentConfirm: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                var res = await jq.post(ref.url('api/v1/agent/ajax/add_to_intake_agent_all_students'));
                this.getListData()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        checkIntakeInDeadline: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/check_in_intake_deadline'), params);
                ref.intakeItem = res.data.intake
                if (res.data.in_expiry_intake) {
                    this.inDeadline = true
                } else {
                    this.inDeadline = false
                }

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        getListData: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage },  this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_student_list'), params);
                if (res.data.studentList.data && res.data.studentList.data.length) {
                    this.studentList = res.data.studentList.data.map(item => {
                        const StatusData = Object.assign({}, item.submission_status, JSON.parse(item.submission_status.data))
                        return Object.assign({}, item, { submission_status: StatusData })
                    });
                } else {
                    this.studentList = []
                }
                // console.log('this.studentList', this.studentList)
                this.paginationData(res.data.studentList)
                this.gen_download_url()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        getRegionList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_region_dropdown_list'));
            this.regionList = res.data.regionList.map(item => {
                return {
                    id: item.id,
                    label: item.title
                }
            })
        },
        getRegionCountryList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/agent/ajax/get_region_countries_list'));
            this.regionCountryList = res.data.regionCountryList
        },
        getUniversityIntakeList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/agent/ajax/get_university_intake_list'));
            
            this.intakeTreeselectList = res.data.list.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        },
        agent_status: function(row){

            var ref=this;
            return ref.list.agent_statuses.find(each=>{
                return each.id==row.status;
            });

        },
        update_agent_status: async function(){

            this.$refs.agent_status_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/update_agent_status');

            try{

                ref.wait_me('.agency-list-card');
                var res=await jq.post(url, ref.form.agent_status_update);

                ref.form.agent_status_update.agent_id='';
                ref.form.agent_status_update.new_status='';
                ref.alert(res.msg);
                ref.getListData();

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        send_single_mail: function(agent_id){

            var ref=this;
            ref.form.send_invitation.checked_all=false;
            ref.form.send_invitation.agent_ids.push(agent_id);
            ref.invitation_confirmation();

        },
        getCountryList: function (regionId) {
            if (regionId) {
                const regionCountryList = this.regionCountryList.filter(item => item.region_id == regionId)
                const countryMappingData = regionCountryList.map(item => {
                    const countryObj = this.$store.state.site.countries.find(country => country.id == item.country_id)
                    const countryData = {
                        id: countryObj.id,
                        label: countryObj.name,
                    }
                    return Object.assign({}, countryData)
                })
                return countryMappingData
            }
        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        getCourseLevelList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_uni_course_level_list'));
                this.courseLevelList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                this.courseTitleList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                // console.log('this.courseLevelList', res.data.list)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        toggle_modal_form: function(){
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        editData: function (item) {
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.dob = moment(this.editItem.dob).format("DD MMM YYYY");
            this.flag.show_modal_form = !this.flag.show_modal_form;
            // console.log('edit data', item)
        },
        addToIntake: function (item) {
            var ref=this;
            this.editItem = item
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Add To Intake',
                html: "Are you sure do you want to add to intake?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
                input:'select',
                inputPlaceholder: 'Select an Intake',
                inputOptions:ref.list.intakes,
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if(value) resolve();
                        else{
                            resolve('Please! select an intake from dropdown.');
                        }
                    });
                }
            }).then((result) => {
                if(result.isConfirmed && result.value){
                    this.addToIntakeConfirm(item, result.value);
                }
            })
        },
        addToIntakeConfirm: async function(item, intake_id=null){

            var ref=this;
            var jq=ref.jq();

            var form_data=ref.clone(item);
            form_data.intake_id=intake_id;

            try {
                var res = await jq.post(ref.url('api/v1/agent/ajax/add_to_intake_agent_student'), form_data);
                this.getListData()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/update_agent_student_info'), ref.editItem);
                this.getListData()
                this.toggle_modal_form()
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        getStatusList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'agent_student_submission_statuses' }
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_statuses_by_groups'), params);
                this.statusTreeSlectList = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })
                // console.log('res.data.statuses', this.statusTreeSlectList)
                this.statusList = res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        gen_download_url: function () {
            var ref=this;
            var jq=this.jq();
            // var qstr = new URLSearchParams(this.search).toString()
            var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
            this.download_url = ref.url('download/agent_students_export_excel/' + hash_ids.encode(ref.store_auth_user.institute_id));
            this.search.auth_id = ref.store_auth_user.id
            var search_query = jq.param(this.search)
            this.download_url += '?' + search_query

        },
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.$store.state.site.countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        },
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        }
    }
}
</script>
<style type="text/css">
    .badge.badge-light-blue {
        background-color: rgba(51, 51, 255, 0.12);
        color: #3333ff !important;
    }        
    .badge.badge-light-declined {
        background-color: rgba(255, 51, 0, 0.12);
        color: #ff3300 !important;
    }
    .table th, .table td {
         padding: 0.72rem 0.5rem !important; 
        vertical-align: middle;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 0.486rem .7rem !important; 
    }
    .banner {
        margin-top: -20px;
        margin-left: -20px;
        margin-bottom: -20px;
        /* padding: 15px; */
        height: 80px;
        line-height: 80px;
        color: #FFF;
        text-align: left;
        padding: 10px 10px;
    }
    .close_button {
        cursor: pointer;
    }
</style>
