
import Login from './Login.vue';
import NotFound from './NotFound.vue';
import Dashboard from './Dashboard.vue';
//import AgentSignup from './AgentSignup.vue';
import AccountSettings from './AccountSettings.vue';
import AddStudent from './AddStudent.vue';
import AllStudentList from './ManageStudents.vue';
import StudentListByIntake from './StudentListByIntake.vue';
import AddMultipleStudentIntakeWise from './AddMultipleStudentIntakeWise.vue';
import AddNewMultipleStudent from './AddNewMultipleStudent.vue';
import SubmitToUniversity from './SubmitToUniversity.vue';
import ForgotPassword from './ForgotPassword.vue';
import ResetPassword from './ResetPassword.vue';
import UnassignedStudents from './UnassignedStudents.vue';
import ChangePassword from './ChangePassword.vue';
import ConfirmedStudentList from './ConfirmedStudentList.vue';
import IntakeWiseStudentList from './IntakeWiseStudentList.vue';
import Communications from './Communications.vue';
import GeneralSettings from './settings/GeneralSettings.vue';
import BankAccountList from './settings/BankAccountList.vue';
import Logout from './Logout.vue';
import FuturePaymentStudentList from './FuturePaymentStudentList.vue';

export default [
    {
      path: '/',
      component: Login,
      name: 'Login'
    },
    {
      path: '*',
      component: NotFound
    },
    {
      path: '/dashboard',
      component: Dashboard
    },
    {
      path: '/sign-up/:signup_token',
      component: ()=>import('./AgentSignup.vue'),
      name: 'AgentSignup'
    },
    {
      path: '/account-settings',
      component: ()=>import('./AccountSettings.vue'),
      name: 'AccountSettings'
    },
    {
      path: '/add-student',
      component: AddStudent,
      name: 'AddStudent'
    },
    {
      path: '/add-new-multiple-student',
      component: AddNewMultipleStudent,
      name: 'AddNewMultipleStudent'
    },
    {
      path: '/student-list',
      component: AllStudentList,
      name: 'AllStudentList'
    },
    {
      path: '/student-list/:id',
      component: StudentListByIntake,
      name: 'StudentListByIntake'
    },
    {
      path: '/add-student/:intake_id?',
      component: AddStudent,
      name: 'AddStudent'
    },
    {
      path: '/add-multiple-student/:intake_id?',
      component: AddMultipleStudentIntakeWise,
      name: 'AddMultipleStudentIntakeWise'
    },
    {
      path: '/submit-to-university',
      component: ()=>import('./SubmitToUniversity.vue'),
      name: 'SubmitToUniversity'
    },
    {
      path: '/forgot-password',
      component: ForgotPassword,
      name: 'ForgotPassword'
    },
    {
      path: '/reset-password/:password_reset_token',
      component: ResetPassword,
      name: 'ResetPassword'
    },
    {
      path: '/unassigned-students/:intake_hashed_id',
      component: UnassignedStudents,
      name: 'UnassignedStudents'
    },
    {
      path: '/change-password',
      component: ChangePassword,
      name: 'ChangePassword'
    },
    {
      path: '/student-list/:list_id/:intake_id',
      component: ConfirmedStudentList,
      name: 'ConfirmedStudentList'
    },
    {
      path: '/intake-wise-student-list/:list_id/:intake_id/:active_tab?',
      component: IntakeWiseStudentList,
      name: 'IntakeWiseStudentList'
    },
    {
      path: '/communications/:student_id?',
      component: Communications,
      name: 'Communications'
    },
    {
      path: '/settings/general-settings',
      component: GeneralSettings,
      name: 'GeneralSettings'
    },
    {
      path: '/settings/bank-list',
      component: BankAccountList,
      name: 'BankAccountList'
    },
    {
      path: '/logout',
      component: Logout,
      name: 'Logout'
    },
    {
      path: '/future-payment-student-list',
      component: FuturePaymentStudentList,
      name: 'FuturePaymentStudentList'
    },
    {
      path: '/view-all-claims',
      component: ()=>import('./ViewAllClaims.vue'),
      name: 'ViewAllClaims'
    },
]