<template>
<div class="upload-avatar-wrapper rounded">
    <label v-show="!new_avatar_url" class="hand avatar-upload-icon" :for="'vue-avatar-upload-input-'+label_for_id">      
        <!-- <i class="fa fa-camera text-primary"></i> -->
        <span class="fa-stack" style="color: rgba(0, 0, 255, 0.1);" title="Update Image">
        <i class="fa fa-circle fa-stack-2x"></i>
        <i class="fa fa-camera fa-stack-1x text-primary"></i>
        </span>
    </label>

    <div v-show="new_avatar_url" class="vue-avatar-upload-or-cancel">
        <span class="fa-stack hand" v-on:click="reset_avatar" style="color: rgba(255, 0, 0, 0.1);" title="Cancel">
        <i class="fa fa-circle fa-stack-2x"></i>
        <i class="fa fa-times fa-stack-1x text-danger"></i>
        </span>

        <span class="fa-stack hand" v-on:click="confirmed_changed_avatar" style="color: rgba(0, 255, 0, 0.2);" title="Confirm">
        <i class="fa fa-circle fa-stack-2x"></i>
        <i class="fa fa-check fa-stack-1x text-success"></i>
        </span>
    </div>

    <img
        class="img-fluid logo-image-holder"
        :src="avatar_preview_url"
        onerror="this.src='https://via.placeholder.com/350x150.jpg?text=Upload+Image'"
    />

    <input
        :id="'vue-avatar-upload-input-'+label_for_id"
        type="file"
        v-on:change="vue_avatar_changed"
        style="display: none;"
    />
</div>
</template>

<script>

export default {
    name: "VueUploadAvatar",
    props:['init_img'],
    data:()=>{
        return {
            file_input: null,
            file_ref: {
                value:''
            },
            new_avatar_url: null,
            label_for_id: 0
        };
    },
    methods:{
        vue_avatar_changed: function(e){
            this.file_ref=e.target;
            this.file_input=e.target.files[0];
            if(this.file_input) this.new_avatar_url=URL.createObjectURL(this.file_input);
        },
        reset_avatar: function(){

            this.new_avatar_url=null;
            this.file_ref.value='';
            this.file_input=null;
            
        },
        confirmed_changed_avatar: function(){

            var form_data=new FormData();
            form_data.append('image_file', this.file_input);            
            this.$emit('confirmedUpload', form_data);

        }
    },
    computed:{

        avatar_preview_url: function(){

            if(this.new_avatar_url) return this.new_avatar_url;
            else if(this.init_img) return this.init_img;
            else return "https://via.placeholder.com/350x150.jpg?text=Upload+Image";

        }

    },
    watch:{
        init_img: function(){
            this.reset_avatar();
        }
    },
    created: function(){
        this.label_for_id=Math.floor(Math.random() * 100) + 100;
    }
}
</script>

<style>
.upload-avatar-wrapper{
    position: relative;
    border: 0.5px solid #ddd;
    margin-top: 16px;
    padding: 8px;
}

.avatar-upload-icon{
    position: absolute;
    right: 8px;
    top: 8px;
}

.vue-avatar-upload-or-cancel{
    position: absolute;
    right: 8px;
    top: 8px;
}
</style>
