<template>
  <section class="root">
    <sidebar-form
      :show_sidebar="flag.show_list_requests"
      v-on:toggle-sidebar="toggle_list_request_form()"
    >
      <div class="list-requests-section">
        <div class="mb-1">
          <h5>
            <span class="align-middle">Communications with University</span>
            <span class="badge badge-primary hand ml-50" v-on:click="form.list_request.show_form= !form.list_request.show_form">Add New</span>
          </h5>
        </div>

      <div class="card mb-1" v-show="form.list_request.show_form">
        <div class="card-body">

          <div class="list-request-form-section">
            <div class="row">
              <div class="col form-group">
                <textarea
                  class="form-control"
                  rows="2"
                  placeholder="Enter your message"
                  v-model="form.list_request.message"
                ></textarea>
              </div>
            </div>

            <div class="row">
                <div class="col form-group">
                    <label for="req_types" class="form-label">
                        Select Type
                    </label>
                    <treeselect v-model="form.list_request.req_type_id" :options="treeselect_req_types" placeholder="Select Communication Type" autocomplete="off" id="req_types"/>
                </div>
            </div>

            <div class="attachment-list d-flex justify-content-between align-items-center mb-50" v-for="(attachment, index) in form.attachments" :key="index">
              <div class="mr-50">
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x text-info"></i>
                  <i class="fa fa-paperclip fa-stack-1x fa-inverse"></i>
                </span>
                <!-- <span v-html="attachment.name"></span> -->
              </div>
              <div class="flex-grow-1">
                <input type="text" class="form-control form-control-sm" :placeholder="attachment.file.name+' (Change file name)'" v-model="attachment.remarks"/>
              </div>
              <div class="ml-50">
                <i class="fa fa-times-circle text-danger fa-2x hand mr-25" aria-hidden="true" v-on:click="remove_attachment(index)"></i>
              </div>
            </div>

            <div class="row">
                <div class="col form-group">
                    <label for="req_attachment" class="form-label">
                        Attachments:
                      <span class="text-warning ml-25 d-block">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        Each attached file size limt is <strong>5MB</strong>.
                      </span>
                      <span class="text-warning ml-25 d-block">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        File extension must have to be <strong>.png, .jpg, .pdf, .xlsx, .xls, .doc, .docx, .csv or .txt</strong>
                      </span>
                    </label>
                    <input type="file" class="form-control-file" placeholder="Attachment" ref="req_attachment" v-on:change="add_attachment"/>                  
                </div>
            </div>

            <div class="row">
              <div class="col form-group">
                <button
                  class="
                    btn btn-primary btn-sm
                    waves-effect waves-float waves-light
                  "
                  v-on:click="save_list_request()"
                >
                  Save
                </button>
                <button
                  class="
                    btn btn-light btn-sm
                    waves-effect waves-float waves-light ml-50
                  "
                  v-on:click="close_new_request_form()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
        
      </div>

      <div class="list-req-details" v-if="list.student_list_requests && list.student_list_requests.length > 0">

        <div v-for="(active_list_req, index) in list.student_list_requests" :key="index">
            <div class="card mb-1" v-if="active_list_req.agent_student_list">
                <div class="card-body" v-if="index == 0">
                    <div class="form-row">
                        <div class="col-lg-12 text-right pb-50" v-if="cn(active_list_req, 'request_type', null)">
                            <span v-html="active_list_req.request_type.name" class="badge badge-pill mr-1" :class="'badge-light-'+cn(active_list_req, 'request_type.parsed_data.color', 'primary')"></span>

                            <span v-show="active_list_req.status=='Open'" v-tooltip="'Click to update status'" class="badge badge-light-warning badge-pill">Open</span>
                            <span v-show="active_list_req.status=='Closed'" v-tooltip="'Click to update status'" class="badge badge-light-success badge-pill">Closed</span>
                        </div>
                    </div>

                    <!-- /Change communication status within details -->
                    <div class="row mb-75">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-left align-items-center" v-if="active_list_req.agent_student_list">
                                <div class="avatar-wrapper" v-if="active_list_req.agent_student_list.agent_company.country">
                                    <div class="avatar  mr-1" v-tooltip="active_list_req.agent_student_list.agent_company.country ? 'Agent Country: ' + active_list_req.agent_student_list.agent_company.country.name : ''">
                                        <img :src="active_list_req.agent_student_list.agent_company.country.flag_url" alt="Avatar" height="32" width="32">
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <small v-tooltip="'Agent Company Name'" class="font-weight-bolder font-size-16" v-html="active_list_req.agent_student_list.agent_company.name"></small>
                                    <div>
                                        <small v-tooltip="'Banner Code'" class="badge badge-pill badge-light-primary cursor-pointer font-size-12" @click="gotoAgentProfilePage(active_list_req.agent_student_list.agent_company)" v-html="active_list_req.agent_student_list.agent_company.agent_code"></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-50 border-top" v-if="active_list_req.agent_student">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-left align-items-center">
                                <div>
                                    <small v-tooltip="'Student Name'" class="font-weight-bolder" v-html="active_list_req.agent_student.first_name + ' ' + active_list_req.agent_student.last_name"></small>
                                    (<small v-tooltip="'Student ID'" v-html="'Student ID: ' + active_list_req.agent_student.uni_ref_no"></small>), 
                                    <small v-tooltip="'Student Country'" class="font-weight-bolder" v-if="active_list_req.agent_student.country" v-html="active_list_req.agent_student.country.name"></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-50 border-top" v-else>
                        <div class="col-md-12">
                            <small><i v-html="'This comment is related to a list.'"></i></small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card p-1">
                <div class="d-flex justify-content-between">
                    <div v-if="cn(active_list_req, 'added_by.user_type', null) && active_list_req.added_by.user_type=='agent'">
                        <span class="badge badge-light-secondary badge-pill"><i class="fa fa-user mx-25"></i> Agent</span>
                    </div>
                    <div v-else>
                        <span class="badge badge-light-primary badge-pill"><i class="fa fa-university mx-25"></i> University</span>
                    </div>
                    <span class="badge badge-light-primary badge-pill hand ml-1" v-tooltip="'List Ref'" @click="gotoAgentWiseStudentListPage(active_list_req)" v-if="active_list_req.agent_student_list" v-html="'List Ref - ' + active_list_req.agent_student_list.list_code"></span>
                </div>
                <div class="font-size-18 font-weight-bold py-50">
                    <span v-html="active_list_req.message"></span>

                    <div v-if="cn(active_list_req, 'attachments.length', 0)">
                        <a class="mr-25" v-for="(attachment, index) in active_list_req.attachments" :href="url('tmp_aws_media/'+attachment.file)" :key="index">
                            <span class="fa-stack font-size-14">
                                <i class="fa fa-circle fa-stack-2x"></i>
                                <i class="fa fa-cloud-download fa-stack-1x fa-inverse"></i>
                            </span>
                        </a>
                    </div>

                </div>
                <div class="border-0">
                    <small>
                        <i class="fa fa-clock-o mx-25" aria-hidden="true"></i>
                        <span v-html="dDateTime(active_list_req.created_at) + ' (' + dNow(active_list_req.created_at) + ')'"></span>
                    </small>
                </div>
            </div>

            <div class="row" v-show="active_list_req.status=='Open'">
                <div class="col text-right mt-50">
                    <span class="badge badge-light-secondary hand" v-on:click="toggle_comment_form(active_list_req)">Add a Reply</span>
                </div>
            </div>

            <div class="list-req-comment-form" v-if="active_list_req.show_comment_form">
                <div class="row mt-50">
                    <div class="col form-group">
                    <textarea class="form-control" rows="2" v-model="form.list_req_comment.comment" placeholder="Add reply here..."></textarea>
                    </div>
                </div>

                <div class="attachment-list d-flex justify-content-between align-items-center mb-50" v-for="(attachment, index) in form.attachments" :key="index">
                    <div class="mr-50">
                    <span class="fa-stack fa-lg">
                        <i class="fa fa-circle fa-stack-2x text-info"></i>
                        <i class="fa fa-paperclip fa-stack-1x fa-inverse"></i>
                    </span>
                    </div>
                    <div class="flex-grow-1">
                    <input type="text" class="form-control form-control-sm" :placeholder="attachment.file.name+' (Change file name)'" v-model="attachment.remarks"/>
                    </div>
                    <div class="ml-50">
                        <i class="fa fa-times-circle text-danger fa-2x hand mr-25" aria-hidden="true" v-on:click="remove_attachment(index)"></i>
                    </div>
                </div>

                <div class="row">
                    <div class="col form-group">
                        <label for="req_attachment" class="form-label">
                            Attachments:
                            <span class="text-warning ml-25 d-block">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            Each attached file size limt is <strong>5MB</strong>.
                            </span>
                            <span class="text-warning ml-25 d-block">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            File extension must have to be <strong>.png, .jpg, .pdf, .xlsx, .xls, .doc, .docx, .csv or .txt</strong>
                            </span>
                        </label>
                        <input type="file" class="form-control-file" placeholder="Attachment" ref="comment_attachment" v-on:change="add_attachment"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col form-group">
                        <button class="btn btn-primary btn-sm waves-effect waves-float waves-light" v-on:click="save_list_req_comment(active_list_req)">
                            Save
                        </button>
                        <button class="btn btn-light btn-sm waves-effect waves-float waves-light ml-50"
                            v-on:click="toggle_comment_form(active_list_req)"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

            <div class="req-comments-section" v-if="active_list_req.comments && active_list_req.comments.length">
                <div class="comment-wrapper" v-for="row in active_list_req.comments" :key="row.id">
                    <!-- <div class="comment-block mt-1 mb-25 p-2"> -->
                    <div class="mt-1 mb-25 p-2" :class="auth_user.id == row.added_by.id ? 'comment_block_white' : 'comment_block_white'">
                        <div class="d-flex justify-content-left align-items-center">
                        <div class="avatar-wrapper">
                            <div class="avatar  bg-light-primary  mr-1">
                                <span class="avatar-content" v-html="avatarText(row.added_by.name)"></span>
                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <small class="font-weight-bolder font-size-13 text-justify" v-html="row.comment"></small>
                            <div v-if="row.attachments">
                                <a style="font-size: 13px" v-for="(attachment, index) in row.attachments" :key="index" :href="url('tmp_aws_media/'+attachment.file)" target="_blank" class="download-comment-attachment">
                                    <span class="fa-stack mr-25">
                                        <i class="fa fa-circle fa-stack-2x"></i>
                                        <i class="fa fa-cloud-download fa-stack-1x fa-inverse"></i>
                                    </span>
                                    <span v-if="attachment.note" v-html="attachment.note"></span>
                                    <span v-else v-html="attachment.original_name"></span>
                                </a>
                            </div>
                            <small class="emp_post text-muted mt-1">
                                <div v-if="auth_user.id == row.added_by.id" class="text-uclan-b5">
                                    <i v-if="cn(row, 'added_by.user_type', null) && row.added_by.user_type=='agent'" class="fa fa-user mx-25"></i>
                                    <i v-else class="fa fa-university mx-25"></i>
                                    <span v-html="cn(row, 'added_by.name', 'N/A')"></span>

                                    <i class="fa fa-calendar-plus-o ml-75 mr-25" aria-hidden="true"></i>
                                    <span v-html="dDate(row.created_at)"></span>
                                    <i class="fa fa-clock-o ml-75 mr-25" aria-hidden="true"></i>
                                    <span v-html="dTime(row.created_at)"></span>
                                </div>
                                <div v-else class="font-weight-bold">
                                    <i v-if="cn(row, 'added_by.user_type', null) && row.added_by.user_type=='agent'" class="fa fa-user mx-25"></i>
                                    <i v-else class="fa fa-university mx-25"></i>
                                    <span v-html="cn(row, 'added_by.name', 'N/A')"></span>

                                    <i class="fa fa-calendar-plus-o ml-75 mr-25" aria-hidden="true"></i>
                                    <span v-html="dDate(row.created_at)"></span>
                                    <i class="fa fa-clock-o ml-75 mr-25" aria-hidden="true"></i>
                                    <span v-html="dTime(row.created_at)"></span>
                                </div>
                            </small>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="card mb-1" v-else>
        <div class="card-body">
            <div class="row text-center">
                <div class="col">
                  <span v-if="list.student_list_requests == null" >Processing...</span>
                    <span v-else >
                      <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i> Found no communication.
                    </span>
                </div>
            </div>
        </div>
      </div>

    </sidebar-form>
  </section>
</template>

<script>
// import SidebarForm from "../../SidebarForm";
import SidebarForm from "../../SidebarFormLighterBlue";
import Treeselect from '@riophae/vue-treeselect';

export default {
  name: "StudentListRequest",
  props: ['list_id'],
  components: {
    SidebarForm,
    Treeselect
    //BTabs, BTab,
    //HomeIcon, UserIcon,
  },
  data: () => {
    return {
      list: {
        student_list_requests: null,
        list_req_comments:[],
        list_req_types:[],
        list_req_statuses:{
          'Open':'Open',
          'Closed': 'Closed'
        }
      },
      form: {
        list_request:{
            show_form: false,
            student_id:'',
            message:'',
            list_id:'',
            req_type_id:null
        },
        list_req_comment:{
          show_form: false,
          list_request_id:'',
          comment:''
        },
        list_req_status:{
          id:'',
          status:'',
          status_remarks:''
        },
        //file_form_data:null,
        attachments:[]
      },
      flag: {
        show_list_requests: false,
        show_list_req_details: false
      },
      info:{
        active_list_req:{}
      },
      agentStudentProps: '',
    };
  },
  created: function () {
    var ref = this;
    ref.setup_urls();
  },
  mounted: function(){
    this.init_data();
  },
  methods: {
    init_data: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v1/agent/ajax/get_as_list_req_init_data');

        try{
            var res=await jq.get(url);
            ref.list.list_req_types=res.data.list_req_types;
        }catch(err){
            ref.alert(ref.err_msg(err), 'error');
        }

    },
    toggle_list_request_form: function () {
      var ref = this;
      var form = ref.form.list_request;
      ref.flag.show_list_requests = !ref.flag.show_list_requests;

      if (!ref.flag.show_list_requests) {
        form.message = "";
        form.list_id = "";
        form.student_id = "";
        form.req_type_id=null;
      } else {
        ref.close_new_request_form();
        ref.close_list_req_details();
        ref.get_student_list_requests();
      }
    },
    open_list_request_form: function(agent_student_list){

        var ref=this;
        var form=ref.form.list_request;
        form.list_id=agent_student_list.id;
        this.toggle_list_request_form();

    },
    open_list_req_with_student: function(agent_student){

        var ref=this;
        var form=ref.form.list_request;
        form.list_id=agent_student.list_id;
        form.student_id=agent_student.id;
        this.agentStudentProps = agent_student
        this.toggle_list_request_form();

    },
    save_list_request: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v1/agent/ajax/save_student_list_request');

        var form_data=new FormData();

        ref.form.attachments.forEach((row, index)=>{
          form_data.append('attachments['+index+'][file]', row.file);
          form_data.append('attachments['+index+'][remarks]', row.remarks);
        });

        form_data.append('student_id', ref.form.list_request.student_id);

        form_data.append('message', ref.form.list_request.message);

        form_data.append('list_id', ref.form.list_request.list_id);

        form_data.append('req_type_id', ref.form.list_request.req_type_id);

        ref.wait_me('.sidebar-form');

        jq.ajax({
            url: url,
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{

                ref.alert(res.msg);
                ref.close_new_request_form();
                ref.get_student_list_requests();

            }
        }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
        ).always(()=>ref.wait_me('.sidebar-form', 'hide'));

    },
    get_student_list_requests: async function(){

        var ref=this;
        var jq=ref.jq();
        // var form=ref.form.list_request;

        var qstr={
            list_id: this.agentStudentProps.list_id,
            student_id: this.agentStudentProps.id
        }

        var url=ref.url('api/v1/agent/ajax/get_student_list_requests');

        try{
            ref.list.student_list_requests= null;
            ref.wait_me('.list-requests-section');
            var res=await jq.get(url, qstr);
            ref.list.student_list_requests=res.data.student_list_requests;
        }catch(err){
            ref.alert(ref.err_msg(err), 'error');
        }finally{
            ref.wait_me('.list-requests-section', 'hide');
        }

    },
    close_new_request_form: function(){

      var form=this.form.list_request;
      form.show_form=false;
      form.message='';
      form.req_type_id=null;
      //this.form.file_form_data=null;
      //this.$refs.req_attachment.value='';
      this.form.attachments=[];

    },
    close_list_req_details: function(){

      var ref=this;
      ref.info.active_list_req={};
      ref.flag.show_list_req_details=false;
      ref.list.list_req_comments=[];

    },
    toggle_comment_form: function(active_list_req){
      active_list_req.show_comment_form = !active_list_req.show_comment_form
    },
    get_list_req_comments: async function(){

        var ref=this;
        var jq=ref.jq();

        var qstr={
            list_request_id: ref.info.active_list_req.id
        }

        var url=ref.url('api/v1/agent/ajax/get_student_list_req_comments');

        try{
            ref.list.list_req_comments=[];
            ref.wait_me('.req-comments-section');
            var res=await jq.get(url, qstr);
            ref.list.list_req_comments=res.data.list_req_comments;
        }catch(err){
            ref.alert(ref.err_msg(err), 'error');
        }finally{
            ref.wait_me('.req-comments-section', 'hide');
        }

    },
    save_list_req_comment: async function(active_list_req){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v1/agent/ajax/save_student_list_req_comment');

      var form_data=new FormData();

      ref.form.attachments.forEach((row, index)=>{
        form_data.append('attachments['+index+'][file]', row.file);
        form_data.append('attachments['+index+'][remarks]', row.remarks);
      });

      form_data.append('list_request_id', active_list_req.id);

      form_data.append('comment', ref.form.list_req_comment.comment);

      ref.wait_me('.sidebar-form');

      jq.ajax({
          url: url,
          data: form_data,
          processData: false,
          contentType: false,
          type: 'POST',
          success: res=>{
            
            var form = ref.form.list_request;
            form.message = "";
            form.list_id = "";
            form.student_id = "";
            form.req_type_id=null;

              ref.alert(res.msg);
              ref.toggle_comment_form(active_list_req);
              // ref.get_list_req_comments();
              ref.get_student_list_requests();
          }
      }).fail(
          err=>ref.alert(ref.err_msg(err), 'error')
      ).always(()=>ref.wait_me('.sidebar-form', 'hide'));

    },
    add_attachment: function(e){

      var ref=this;

      var file_input=e.target.files[0];

      setTimeout(function(){
        e.target.value='';
        ref.form.attachments.push({
          file: file_input,
          remarks: ''
        });
      }, 1000);
      
        
      /* this.form.file_form_data.append(
          'attachment', file_input
      ); */

    },
    remove_attachment: function(index){
      this.remove_row(this.form.attachments, index);
    }
  },
  computed: {
      treeselect_req_types: function(){

          return this.list.list_req_types.map(item => {
              return {
                  id: item.id,
                  label: item.name
              }
          });

      }
  }
};
</script>

<style scoped>
.comment-block{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-top: 8px;
  border-radius: 25px;
}

.attachment-list{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  /* margin-top: 8px; */
  border-radius: 25px;
}

.download-attachment{
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  margin-top: 3px;
  margin-right: 5px;
  /* padding: 1px 1px; */
  padding: 1px;
  padding-right: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 16px;
}

.download-comment-attachment{
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  margin-top: 3px;
  margin-left: 5px;
  /* padding: 1px 1px; */
  padding: 1px;
  padding-right: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 16px;
}

.avater_size_25 {
    width: 25px !important;
    height: 25px !important;
    font-size: 10px !important;
    font-weight: 700 !important;
}
</style>
