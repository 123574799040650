<template>
    <div class="sidebar-div row" v-show="show_sidebar">
            <div class="container">
                <div class="overlay col-lg-7 col-md-7" v-on:click="toggle_sidebar()"></div>
                <div class="sidebar-form col-lg-5 col-md-5 scrollbar_design">
                    <!-- <button type="button" v-on:click="toggle_sidebar()" class="close cross_button_design">
                        <x-icon></x-icon>                      
                    </button> -->
                    <i v-on:click="toggle_sidebar()" class="fa fa-times-circle font-size-22 cursor-pointer cross_button_design text-danger" aria-hidden="true"></i>
                    <slot></slot>
                </div>
            </div>
        </div>
</template>

<script>
// import { XIcon } from 'vue-feather-icons'

export default{
    name: 'SidebarForm',
    props:{
        show_sidebar:{
            type: Boolean,
            default: false
        },
        title: String,
    },
    components:{
        // XIcon
    },
    data () {
        return {
            showModal: false,
        }
    },
    methods:{
        toggle_sidebar: function(){
            this.$emit('toggle-sidebar', !this.show_sidebar);
        }
    }
}
</script>

<style scoped>

    .sidebar-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: #66666663;
        z-index: 999;
        transition: 1s ease;
    }
    .sidebar-form {
        /* float: right; */
        height: 100% !important;
        position: fixed !important;
        top: 0px !important;
        right: 0px !important;
        z-index: 999999;
        background: #fff;
        padding: 20px;
        box-shadow: 5px 5px 20px -5px #000;
        /* transition: transform 2s;
         */
    }

    .cross_button_design {
        position: absolute;
        top: 5px;
        right: 15px;
        z-index: 99999;
    }

    .scrollbar_design {
        width: 100%;
        height: auto;
        overflow-y: auto;
        /* overflow-x: hidden; */
    }

    .scrollbar_design::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
        border-radius: 10px;
    }

    .scrollbar_design::-webkit-scrollbar
    {
        width: 10px;
        background-color: #F5F5F5;
    }

    .scrollbar_design::-webkit-scrollbar-thumb
    {
        background-color: #047EAF;
        border-radius: 10px;
        background-image: -webkit-linear-gradient(0deg,rgba(255, 255, 255, 0.5) 25%, transparent 25%, transparent 50%,rgba(255, 255, 255, 0.5) 50%,rgba(255, 255, 255, 0.5) 75%, transparent 75%,transparent)
    }
</style>
