<template>
<section class="sams-pay-university">
    <router-view></router-view>
</section>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import Routes from './SamsPayUclanAgent/routes.js';
import $ from 'jquery';
import vClickOutside from 'v-click-outside';

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

//import AppJs from '../src/custom/vuexy/app.js';
//import AppMenuJs from '../src/custom/vuexy/app-menu.js';
import feather from 'feather-icons';

$('body').addClass('pace-done horizontal-layout horizontal-menu navbar-floating footer-static');
$('body').attr('data-open', 'hover');
$('body').attr('data-menu', 'horizontal-menu');

//AppJs(window, document, $);
//AppMenuJs(window, document, $);
// We listen to the resize event

window.addEventListener('resize', function () {
  // We execute the same script as before
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
});

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(vClickOutside);

const store=new Vuex.Store({
  state:{
    base_url:'',
    api_url:'',
    api_token:'',
    //api_key:'',
    site:{
      agent_company_logo_url: null,
      agent_company: {
        logo_url: null
      },
      auth_user:{
        name: 'Unknown'
      },
      countries:[],
      course_levels:[],
      unreadCommentsCount: 0
    }
  },
  mutations: {
    setBaseUrl: function(state, value){
      state.base_url=value;
    },
    setApiUrl: function(state, value){
      state.api_url=value;
    },
    setApiToken: function(state, value){
      state.api_token=value;
    },
    /*setApiKey: function(state, value){
      state.api_key=value;
    },*/
    siteInfo: function(state, args){
      state.site[args.key]=args.val;
    }
  }
});

const router=new VueRouter({
  routes: Routes
});

router.beforeEach((to, from, next)=>{

  //var jwt_token=localStorage.getItem('jwt_token');
  var api_token=localStorage.getItem('agent_api_token');

  if(to.name=='AgentSignup' || to.name=='ForgotPassword' || to.name=='ResetPassword') next();
  else if(to.name!=='Login' && !api_token) {
    sessionStorage.setItem('redirectPath', to.path)
     next({ name: 'Login' });
  } else if(to.name=='Login' && api_token) next('/dashboard');
  else next();
  
});

export default {
  store,
	router,
	name: 'SamsPayAgent',
	props: ['api_url', 'base_url', 'api_token'/*, 'api_key'*/],
  metaInfo: {
    title: 'Agent Commission Management | University of Central Lancashire',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Agent Commission Management, University of Central Lancashire' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
	created: async function(){

    var ref=this;
    var jq=ref.jq();

		ref.$store.commit('setBaseUrl', ref.base_url);
		ref.$store.commit('setApiUrl', ref.api_url);

    var api_token=localStorage.getItem('agent_api_token');    

    if(api_token){

      ref.$store.commit('setApiToken', api_token);
      ref.ajax_setup('agent_api_token');

      try{

        var res=await jq.get(ref.url('api/v1/agent/ajax/auth_user_info'));

        if(res.data.auth_user.user_type!='agent'){
          localStorage.removeItem('agent_api_token');
          ref.$router.push('/login');
        }

        ref.$store.commit('siteInfo', {
            key: 'agent_company',
            val: res.data.agent_company
        });

        ref.$store.commit('siteInfo', {
            key: 'agent_company_logo_url',
            val: this.cn(this.$store.state, 'site.agent_company.logo_url', null)
        });

        ref.$store.commit('siteInfo', {
          key: 'auth_user',
          val: res.data.auth_user
        });

        ref.$store.commit('siteInfo', {
          key: 'countries',
          val: res.data.countries
        });
        
        ref.$store.commit('siteInfo', {
          key: 'course_levels',
          val: res.data.course_level
        });

      }catch(err){
        ref.alert(ref.err_msg(err), 'error');
      }

    }

	},
  mounted: function(){
    
    feather.replace();

  }
}

</script>

<style>
@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,400;1,500;1,600";
@import '../static/vuexy/app-assets/vendors/css/vendors.min.css';
@import '../static/vuexy/app-assets/css/bootstrap.css';
@import '../static/vuexy/app-assets/css/bootstrap-extended.css';
@import "../static/vuexy/app-assets/css/colors.css";
@import "../static/vuexy/app-assets/css/components.css";
@import "../static/vuexy/app-assets/css/themes/dark-layout.css";
@import "../static/vuexy/app-assets/css/themes/bordered-layout.css";
@import "../static/vuexy/app-assets/css/themes/semi-dark-layout.css";
@import "../static/vuexy/assets/css/style.css";
@import "../static/vuexy/app-assets/css/plugins/forms/form-wizard.css";
@import "../static/vuexy/app-assets/vendors/css/forms/wizard/bs-stepper.min.css";
@import "../static/vuexy/app-assets/css/core/menu/menu-types/horizontal-menu.css";

@import "../static/waitMe/waitMe.min.css";

@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
@import "~flatpickr/dist/flatpickr.min.css";
@import "~noty/lib/noty.css";
@import "~noty/lib/themes/sunset.css";
@import "~bootstrap-sweetalert/dist/sweetalert.css";
@import "~@riophae/vue-treeselect/dist/vue-treeselect.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

.hand{
   cursor: pointer;
}

.swal2-cancel {
  margin-right: 10px;
}
.form-control:focus {
    color: #6e6b7b;
    background-color: #fff;
    border-color: rgb(4, 126, 175) !important;
    outline: 0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li.active > a {
    background: rgb(4, 126, 175) !important;
    box-shadow: unset !important;
    color: #fff !important;
    border-radius: 4px !important;
}
.page-item.active .page-link {
    background-color: rgb(4, 126, 175) !important;
}
.horizontal-menu .header-navbar.navbar-horizontal .nav-link.dropdown-toggle::after {
  left: 0.4rem;
 
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")!important;
}

.horizontal-menu .header-navbar.navbar-horizontal .sidebar-group-active .dropdown-toggle::after {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")!important;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
.text-uclan {
    color: rgb(4, 126, 175) !important;
    font-weight: 500;
}
.text-uclan:hover {
    color: rgb(13, 210, 245) !important;
    font-weight: 800;
}
.text-uclan-b {
    color: rgb(4, 126, 175) !important;
    font-weight: 800;
}
.text-uclan-b5 {
    color: rgb(4, 126, 175) !important;
    font-weight: 500;
}
.text-primary{
    color: rgb(4, 126, 175) !important;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
    width: 14rem !important;
    /* margin-top: 10px; */
}
.breadcrumb-item a {
    color: rgb(4, 126, 175) !important;
}
.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-label .bs-stepper-title {
    color: rgb(4, 126, 175) !important;
}
.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-box {
    background-color: rgb(4, 126, 175) !important;
}
.badge-primary {
    background-color: rgb(4, 126, 175) !important;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: rgb(4, 126, 175) !important;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu a:hover {
    color: #ffa8a8 !important;
    /* color: purple !important; */
}
.badge.badge-light-blue {
    background-color: rgba(0, 4, 255, 0.103);
    color: #002fff !important;
}
.badge-purple {
    background-color: #7367F0;
}
.avatar.bg-light-blue {
    background-color: rgba(0, 4, 255, 0.103);
    color: #002fff !important;
}

.border-radius-5 {
  border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-20 {
  border-radius: 20px;
}
.border-radius-50 {
  border-radius: 50px;
}
.border-radius-75 {
  border-radius: 75px;
}
.box-shadow {
  box-shadow: 0px 1px 5px 0px #6a6a6a9c;
}
.box-shadow-inset {
  box-shadow: inset 0 0 5px #dadada, 0 0 5px white;
}
.border-radius-100 {
  border-radius: 100px;
}
.font-size-10{
  font-size: 10px !important;
}
.font-size-11{
  font-size: 11px !important;
}
.font-size-12{
  font-size: 12px !important;
}
.font-size-13{
  font-size: 13px !important;
}
.font-size-14{
  font-size: 14px !important;
}
.font-size-15{
  font-size: 15px !important;
}
.font-size-16{
  font-size: 16px !important;
}
.font-size-18{
  font-size: 18px !important;
}
.font-size-20{
  font-size: 20px !important;
}
.font-size-22{
  font-size: 22px !important;
}
.font-size-24{
  font-size: 24px !important;
}
.font-size-26{
  font-size: 26px !important;
}
.font-size-28{
  font-size: 28px !important;
}
.font-size-30{
  font-size: 30px !important;
}
.bg-light-orange {
    background: rgb(255 253 241);
}

.bg-light-gray {
  background: rgb(212 212 212) !important;
}

.box-shadow {
  box-shadow: 0px 1px 3px #c2c2c2 !important;
}
.zoom {
  transition: 400ms !important;
}
.zoom:hover {
  transform: scale(1.5) !important;
}
.card {
    box-shadow: none !important;
}
.btn {
      border-radius: 50px !important;
}

.modal .modal-header {
    background-color: #047eaf !important;
}
.modal-title {
    color: #FFF !important;
}
.modal-title > span {
    color: #FFF !important;
}
.guideline_div {
    /* border: 2px dashed rgb(115 103 240) !important; */
    border: 2px dashed rgb(4, 126, 175) !important;
    /* width: 100% !important;
    height: 100% !important; */
}
.text_by_other {
    background: #e4e6eb;
    color: #000;
    margin: 0px;
    padding: 10px 10px 10px 10px;
    border-radius: 20px 15px 15px 0px;
}
.text_by_other a{
    color: rgb(0, 225, 255);
}
.text_by_me {
    background: rgb(4, 126, 175);
    color: #FFF;
    padding: 10px 10px 10px 10px;
    border-radius: 15px 20px 0px 15px;
}
.text_by_me a{
    color: rgb(200, 255, 0);
}
.text_by_me a:hover{
    color: rgba(200, 255, 0, 0.651);
}
.comment_block_white{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-top: 8px;
  border-radius: 25px;
}
.comment_block_gray{
  padding: 5px;
  background-color: #e4e6eb;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-top: 8px;
  border-radius: 25px;
}
#header_backgroud {
    /* background: #fbf581 !important; */
    background: #d0d2d6 !important;
    z-index: 999 !important;
    line-height: 1;
    min-height: auto;
}
</style>
