<template>
<section class="submit-to-student">
    <main-layout>
        <!-- <div class="row" v-if="visible">
            <div class="col-md-12">
                 <b-alert show variant="primary" >
                    <div class="alert-body">
                        <x-icon size="1.5x" class="pull-right close_button text-danger" @click="visible = !visible"></x-icon>
                        <span>Here you can see your existing students data and add them accordingly to the respective intake list. You should submit the list within the submission timeline shared by the university through email. Once the list is submitted to the university, you cannot edit, modify your list of student data. Once your list is confirmed, you’ll be notified and find your accepted list in the system.</span>
                    </div>
                </b-alert>
            </div>
        </div> -->

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Submit List</h2> -->
                        <h5 class="content-header-title float-left mb-0" style="margin-top: 5px">All Lists by Intake</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                <li class="breadcrumb-item active">Intake Lists</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card wait_me_list_data">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Intake List</h3>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th width="75px"><span class="ml-1">#</span></th>
                                <th>Intake</th>
                                <th class="text-center">List Ref</th>
                                <th class="text-center">Student</th>
                                <th class="text-center">Dispute</th>
                                <th class="text-center">Confirmed</th>
                                <!-- <th class="text-center">Submitted</th>
                                <th class="text-center">Verified</th>
                                <th class="text-center">Unverified</th> -->
                                <!-- <th>Tution Fees</th>
                                <th>Commission</th> -->
                                <th class="text-center">Submission Timeline</th>
                                <!-- <th class="text-center">Projected Commission</th> -->
                                <th class="text-center">University Status</th>
                                <!-- <th>Action</th> -->
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td><span class="ml-1" v-html="index + pagination.slOffset"></span></td>
                                <td>
                                    <span  @click="gotoIntakeWiseStudentListPage(item)" class="badge badge-pill badge-light-primary" v-html="item.intake_name"></span>
                                </td>
                                <td class="text-center">
                                    <span v-if="item.student_submitted_at" class="badge badge-pill badge-light-success" v-html="item.student_list_code"></span>
                                    <span v-else>-</span>
                                </td>
                                <td class="text-center">
                                    <!-- <span  @click="gotoIntakeWiseStudentListPage(item)"
                                    class="badge badge-pill badge-light-primary"
                                    v-html="item.student"></span> -->
                                    <span v-if="item.list_status"  @click="gotoIntakeWiseStudentListPage(item)"
                                    class="badge badge-pill"
                                    :class="item.list_status.parsed_data ? 'badge-light-' + item.list_status.parsed_data.color : 'primary'"
                                    v-html="item.student"></span>
                                    <span v-else-if="item.student && !item.list_status" @click="gotoIntakeWiseStudentListPage(item)"
                                    class="badge badge-pill badge-light-warning"
                                    v-html="item.student"></span>
                                    <span v-else>-</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="item.dispute_count" @click="gotoDisputeStudentListPage(item)" class="badge badge-pill badge-light-primary" v-html="item.dispute_count">
                                    </span>
                                    <span v-else>-</span>  
                                </td>
                                <td class="text-center">
                                    <span v-if="item.as_confirm_count" @click="gotoConfirmStudentListPage(item)" class="badge badge-pill badge-light-primary" v-html="item.as_confirm_count">
                                    </span>
                                    <span v-else>-</span>                                   
                                </td>
                                <!-- <td class="text-center" v-html="item.submitted_students"></td>
                                <td class="text-center" v-html="item.verified_students"></td>
                                <td class="text-center" v-html="item.unverified_students"></td> -->
                                <!-- <td v-html="item.tuition_fees"></td>
                                <td v-html="item.commission"></td> -->
                                <td class="text-center">
                                    <span v-html="dDate(item.start)"></span><br>
                                    <span v-html="dDate(item.deadline)"></span>
                                </td>
                                <!-- <td class="text-center">-</td> -->
                                <td class="text-center">
                                    <small v-if="item.student && !item.list_status" class="badge badge-pill badge-light-warning" v-html="'Ready to Submit'"></small>
                                    <span v-if="item.list_status">
                                        <!-- <small class="badge badge-pill" :class="item.list_status.parsed_data ? ' badge-light-' + item.list_status.parsed_data.color : ''" v-html="item.list_status.name"></small> -->
                                        <small class="badge badge-pill" :class="item.list_status.parsed_data ? ' badge-light-' + item.list_status.parsed_data.color : 'secondary'" v-html="getUniveristyStatus(item.list_status)"></small>
                                    </span>
                                    <!-- <div v-if="item.as_list">
                                        <div v-tooltip="'Working on International Office'" class="b-avatar badge badge-light-dark rounded ml-1" style="width: 42px; height: 42px;" v-if="item.as_list.io_status == null && item.as_list.fo_status == null && item.as_list.cl_status == null">
                                            <b class="avatar-content" v-html="'IO'"></b>
                                        </div>
                                        <div v-tooltip="'Working on Finance Office'" class="b-avatar badge badge-light-warning rounded ml-1" style="width: 42px; height: 42px;" v-if="item.as_list.io_status != null && item.as_list.fo_status == null && item.as_list.cl_status == null">
                                            <b class="avatar-content" v-html="'FO'"></b>
                                        </div>
                                        <div v-tooltip="'Preparing Confirmed List'" class="b-avatar badge badge-light-success rounded ml-1" style="width: 42px; height: 42px;" v-if="item.as_list.io_status != null && item.as_list.fo_status != null && item.as_list.cl_status == null">
                                            <b class="avatar-content" v-html="'CL'"></b>
                                        </div>
                                        <div v-tooltip="'Waiting for Invoice from Agent'" class="b-avatar badge badge-light-success rounded ml-1" style="width: 42px; height: 42px;" v-if="item.as_list.io_status != null && item.as_list.fo_status != null && item.as_list.cl_status != null">
                                            <b class="avatar-content" v-html="'WI'"></b>
                                        </div>
                                        <div v-tooltip="'Invoiced Received from Agent'" class="b-avatar badge badge-light-danger rounded ml-1" style="width: 42px; height: 42px;" v-if="item.as_list.fo_status != null && item.as_list.cl_status != null && item.as_list.in_status != null">
                                            <b class="avatar-content" v-html="'IR'"></b>
                                        </div>
                                        <div v-tooltip="'Invoice Paid'" class="b-avatar badge badge-light-blue rounded ml-1" style="width: 42px; height: 42px;" v-if="item.as_list.fo_status != null && item.as_list.cl_status != null && item.as_list.in_status != null && item.as_list.ir_status != null">
                                            <b class="avatar-content" v-html="'IP'"></b>
                                        </div>
                                    </div> -->
                                </td>
                                <!-- <td class="text-center"> -->
                                    
                                    <!-- <i v-show="item.list_req_exists" v-tooltip="'Communicate with university'" class="fa fa-comment-o" aria-hidden="true" v-on:click="open_list_requests(item.as_list)"></i> -->
                                    <!-- <i v-tooltip="'Communicate with university'" class="fa fa-comment-o" aria-hidden="true" v-on:click="open_list_requests(item.as_list)"></i> -->
                                    <!-- <div v-if="item.as_list_exists && item.as_list_req_count" @click="open_list_requests(item.as_list)" class="position-relative d-inline-block">
                                        <i class="fa fa-comment-o" v-tooltip="'Communicate with university'"></i>
                                        <span v-if="item.as_list_req_count" class="badge badge-pill badge-light-success badge-up text-success" v-html="item.as_list_req_count"></span>
                                    </div> -->

                                    <!-- <b-dropdown v-if="item.as_list_exists" size="sm" variant="link" class="text-dark" toggle-class="text-decoration-none" no-caret>
                                        <template #button-content>
                                            <span><i class="fa fa-ellipsis-v text-dark" aria-hidden="true"></i></span>
                                        </template>
                                        <b-dropdown-item href="javascript:void(0)" v-if="item.as_list_exists" @click="open_list_requests(item.as_list)">
                                            <i v-tooltip="'Communicate with university'" class="fa fa-comment-o mr-50"></i> Communication
                                        </b-dropdown-item>
                                    </b-dropdown> -->

                                <!-- </td> -->
                                <!-- <td><span class="badge badge-pill badge-light-success" :style="'color: ' + item.status_colour + '!important; background: ' + item.bg_colour_code + '!important;'" v-html="item.status"></span></td> -->
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="10" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        No Intakes are Available.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        >
                    </b-pagination>
                </div>
            </div>
        </div>

        <student-list-request ref="student_list_request"/>

    </main-layout>
</section>
</template>

<script>
import MainLayout from './MainLayout.vue';
import { BPagination, BAlert } from 'bootstrap-vue';
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import { XIcon } from 'vue-feather-icons';
import StudentListRequest from './Partials/StudentListRequest';

export default {
    name: 'SubmitToUniversity',
    metaInfo: {
        titleTemplate: 'UCLan | Intake Lists'
    },
    components:{
        MainLayout,
        BPagination,
        BAlert,
        BDropdown, BDropdownItem,
        XIcon,
        StudentListRequest
    },
    data:()=>{
        return {
            visible: true,
            list: {
                intakes: null
            },
            listData: [],
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: 2,
                slOffset: 1
            },
            flag:{
                show_list_comment_form: false
            },
            form:{
                list_comment:{
                    visible_type:'user_type_agent',
                    message:'',
                    list_id:'',
                    tab_index:0
                }
            },
        }
    },
    methods:{
        getUniveristyStatus: function (status) {
            if (status.code == 'as_list_status_new') {
                return 'List Submitted'
            } else if (status.code == 'as_list_status_in_process') {
                return 'Under Review'
            } else if (status.code == 'as_list_status_completed') {
                return 'Quality Check'
            } else if (status.code == 'as_list_status_notified_agent') {
                return 'Completed'
            } else {
                return status.name
            }
        },
        init_data: async function(){

            var ref=this;
            var jq=ref.jq();

            try{
                ref.wait_me('.wait_me_list_data');
                const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
                // var res= await jq.get(ref.url('api/v1/agent/ajax/pre_uni_submit_intakes'), params);
                var res= await jq.get(ref.url('api/v1/agent/ajax/active_intakes'), params);
                this.listData=res.data;
                this.paginationData(res)

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me(".wait_me_list_data", "hide");
            }

        },
        gotoIntakeWiseStudentListPage: function (item) {
            // this.$router.push({ name: 'StudentListByIntake', params: { id: this.hash_id(item.id) } })
            // this.$router.push({ name: 'IntakeWiseStudentList', params: { list_id: this.hash_id(item.asl_completed_list_id), intake_id: this.hash_id(item.id) } })
            if (item.student_list_id) {
                this.$router.push({ name: 'IntakeWiseStudentList', params: { list_id: this.hash_id(item.student_list_id), intake_id: this.hash_id(item.id) } })
            } else {
                this.$router.push({ name: 'StudentListByIntake', params: { id: this.hash_id(item.id) } })
            }
        },
        gotoConfirmStudentListPage: function (item) {
            if (item.as_confirm_count > 0) {
                // this.$router.push({ name: 'ConfirmedStudentList', params: { list_id: this.hash_id(item.student_list_id), intake_id: this.hash_id(item.id) } })
                this.$router.push({ name: 'IntakeWiseStudentList', params: { 
                    list_id: this.hash_id(item.student_list_id),
                    intake_id: this.hash_id(item.id),
                    active_tab: 'two',
                }})
            }
        },
        gotoDisputeStudentListPage: function (item) {
            if (item.as_confirm_count > 0) {
                // this.$router.push({ name: 'ConfirmedStudentList', params: { list_id: this.hash_id(item.student_list_id), intake_id: this.hash_id(item.id) } })
                this.$router.push({ name: 'IntakeWiseStudentList', params: { 
                    list_id: this.hash_id(item.student_list_id),
                    intake_id: this.hash_id(item.id),
                    active_tab: 'three',
                }})
            }
        },
        gotoStudentListPage: function (item) {
            if (item.as_confirm_count > 0) {
                // this.$router.push({ name: 'ConfirmedStudentList', params: { list_id: this.hash_id(item.student_list_id), intake_id: this.hash_id(item.id) } })
                this.$router.push({ name: 'IntakeWiseStudentList', params: { 
                    list_id: this.hash_id(item.student_list_id),
                    intake_id: this.hash_id(item.id),
                    active_tab: 'zero',
                }})
            }
        },
        open_list_requests: function(as_list){
            this.$refs.student_list_request.open_list_request_form(as_list);
        }
    },
    created: function(){
        this.setup_urls();
    },
    mounted: function(){
        this.init_data();
    }
}
</script>
