<template>
  <section class="dashboard">
    <main-layout>
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h5 class="content-header-title float-left mt-50">Settings</h5>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <!-- <li class="breadcrumb-item active">General Setting</li> -->
                  <li class="breadcrumb-item active">Agent Details</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <!-- account setting page -->
        <section id="page-account-settings">
          <div class="row">
            <!-- left menu section -->
            <div class="col-md-3 mb-md-0">
              <ul class="nav nav-pills flex-column nav-left">
                <!-- general -->
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="account-pill-general"
                    :class="{ active: active_tab === 'general' }"
                    @click="onChangeTab('general')"
                  >
                    <i data-feather="home" class="font-medium-3 mr-1"></i>
                    <span class="font-weight-bold">General </span>
                  </a>
                </li>

                <!-- general -->
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="agent-pill-address"
                    :class="{ active: active_tab === 'headquarter' }"
                    @click="onChangeTab('headquarter')"
                  >
                    <i data-feather="map-pin" class="font-medium-3 mr-1"></i>
                    <span class="font-weight-bold">Headquarter</span>
                  </a>
                </li>
                <!-- information -->
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="account-pill-info"
                    :class="{ active: active_tab === 'contact_person' }"
                    @click="onChangeTab('contact_person')"
                  >
                    <i data-feather="user" class="font-medium-3 mr-1"></i>
                    <span class="font-weight-bold">Key Contact Person</span>
                  </a>
                </li>
                <!-- social -->
                <!-- <li class="nav-item">
                                <a class="nav-link" id="account-pill-info" :class="{ active: active_tab === 'social' }" @click="onChangeTab('social')">
                                    <i data-feather="link" class="font-medium-3 mr-1"></i>
                                    <span class="font-weight-bold">Web & Social</span>
                                </a>
                            </li> -->
              </ul>
            </div>
            <!--/ left menu section -->

            <!-- right content section -->
            <div class="col-md-9">
              <div class="card">
                <div class="tab-content" style="padding: 15px">
                  <!-- general tab -->
                  <div
                    class="tab-pane"
                    :class="{ active: active_tab === 'general' }"
                    id="account-vertical-general"
                  >
                    <div class="form-row">
                      <div class="col-md-3">
                        <vue-upload-avatar
                          class="company-logo-uploader"
                          :init_img="info.company.logo_url"
                          v-on:confirmedUpload="upload_company_logo"
                        ></vue-upload-avatar>
                        <div class="media-body mt-50">
                          <p>
                            Allowed JPG, GIF or PNG. Max size of 800kB
                          </p>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <div class="form-row">
                          <div class="col-12 form-group">
                            <label for="account-username"
                              ><b>Business Name</b>
                              <span class="text-danger">*</span></label
                            >
                            <input
                              readonly
                              type="text"
                              class="form-control"
                              id="account-username"
                              v-model="form.general.company_name"
                              placeholder="Agent Name"
                              value=""
                            />
                          </div>
                          <div class="col-12 form-group">
                            <label for="account-username"
                              ><b>Banner Code</b>
                              <span class="text-danger">*</span></label
                            >
                            <input
                              readonly
                              type="text"
                              class="form-control"
                              id="account-username"
                              v-model="form.general.agent_code"
                              placeholder="Agent Name"
                              value=""
                            />
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label for="account-name"
                                ><b>Admin Email</b>
                                <span class="text-danger">*</span></label
                              >
                              <input
                                readonly
                                type="email"
                                class="form-control"
                                id="account-name"
                                v-model="form.general.admin_email"
                                placeholder="Admin E-mail"
                                value=""
                              />
                            </div>
                          </div>
                          <div class="col-12 col-sm-6">
                            <div class="form-group">
                              <label for="primary_phone"
                                ><b>Primary Phone</b>
                                <span class="text-danger">*</span></label
                              >
                              <div class="input-group">
                                <select
                                  v-model="form.general.agent_mobile_country_id"
                                  class="browser-default custom-select"
                                >
                                  <option selected>Calling code</option>
                                  <option
                                    v-for="(item, index) in countryList"
                                    :key="index"
                                    :value="item.id"
                                  >
                                    {{
                                      item.name + "(+" + item.calling_code + ")"
                                    }}
                                  </option>
                                </select>
                                <div class="input-group-append">
                                  <input
                                    type="text"
                                    v-model="form.general.primary_phone"
                                    class="form-control"
                                    placeholder="Primary Phone"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <button
                              type="button"
                              v-on:click="update_agent_general_data()"
                              class="btn btn-primary"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/ general tab -->

                  <!-- agent-address tab -->
                  <div
                    class="tab-pane wait_me_loader"
                    :class="{ active: active_tab === 'headquarter' }"
                  >
                    <!-- header media -->
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="address_one"
                            ><b>Address line 1</b>
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            v-model="form.headquarter.address_one"
                            class="form-control"
                            id="address_one"
                            placeholder="Address line 1"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="address_two"><b>Address line 2</b> </label>
                          <input
                            type="text"
                            v-model="form.headquarter.address_two"
                            class="form-control"
                            id="address_two"
                            placeholder="Address line 2"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="state"
                            ><b>State</b> <span class="text-danger"></span
                          ></label>
                          <input
                            type="text"
                            v-model="form.headquarter.state"
                            class="form-control"
                            id="state"
                            placeholder="State"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="city">City</label>
                          <input
                            type="text"
                            v-model="form.headquarter.city"
                            class="form-control"
                            id="city"
                            placeholder="City"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="zipcode"><b>Post Code</b></label>
                          <input
                            type="text"
                            v-model="form.headquarter.zipcode"
                            class="form-control"
                            id="zipcode"
                            placeholder="Post Code"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <label class="form-label" for="country_id"
                          ><b>Country</b></label
                        >
                        <treeselect
                          disabled
                          v-model="form.headquarter.country_id"
                          :options="countrySelectList"
                          placeholder="Select Country"
                          id="country_id"
                          autocomplete="off"
                        />
                      </div>
                      <div class="col-12">
                        <button
                          type="button"
                          v-on:click="agent_headquarter_data()"
                          class="btn btn-primary mt-2 mr-1"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--/ general tab -->

                  <!-- information -->
                  <div
                    class="tab-pane wait_me_loader"
                    :class="{ active: active_tab === 'contact_person' }"
                  >
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="account-birth-date"
                            ><b>Key Contact Person's Name</b></label
                          >
                          <input
                            type="text"
                            v-model="form.general.contact_person"
                            class="form-control"
                            placeholder="Contact Person Name"
                            id="account-birth-date"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="account-website"
                            ><b>Key Contact Person's Designation</b>
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            v-model="form.general.contact_person_designation"
                            class="form-control"
                            id="account-website"
                            placeholder="Designation"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="primary_phone"
                            ><b>Key Contact Person's Phone</b> <span class="text-danger">*</span></label
                          >
                          <div class="input-group">
                            <select
                              v-model="form.general.contact_number_country_id"
                              class="browser-default custom-select"
                            >
                              <option selected>Calling code</option>
                              <option
                                v-for="(item, index) in countryList"
                                :key="index"
                                :value="item.id"
                              >
                                {{ item.name + "(+" + item.calling_code + ")" }}
                              </option>
                            </select>
                            <div class="input-group-append">
                              <input
                                type="text"
                                v-model="form.general.contact_number"
                                class="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="account-phone"
                            ><b>Key Contact Person's Email</b> <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            v-model="form.general.contact_email"
                            class="form-control"
                            id="account-phone"
                            placeholder="E-mail"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <button
                          type="button"
                          v-on:click="update_agent_contact_person_data()"
                          class="btn btn-primary mt-1 mr-1"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--/ information -->

                  <!-- social -->
                  <!-- <div class="tab-pane wait_me_loader"  :class="{ active: active_tab === 'social' }">
                                    <div class="row">
                                        <div class="col-12 mb-1">
                                            <h4>
                                                <link-icon size="1.5x" class="custom-class"></link-icon>
                                                <span class="ml-75">Social Links</span>
                                            </h4>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="website">Website</label>
                                                <input type="url" v-model="form.contact_person.website"  class="form-control" placeholder="Website" id="website" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="twitter">Twitter</label>
                                                <input type="url" v-model="form.contact_person.twitter" class="form-control" id="twitter" placeholder="Twitter" />
                                            </div>
                                        </div>                                                    
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="facebook">Facebook</label>
                                                <input type="url" v-model="form.contact_person.facebook" class="form-control" id="facebook" placeholder="Facebook" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="linkedin">LinkedIn</label>
                                                <input type="url" v-model="form.contact_person.linkedin" class="form-control" id="linkedin" placeholder="LinkedIn" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="instagram">Instagram</label>
                                                <input type="url" v-model="form.contact_person.instagram" class="form-control" id="instagram" placeholder="Instagram" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button type="button" v-on:click="agent_web_and_social_data()" class="btn btn-primary mt-1 mr-1">Submit</button>
                                        </div>
                                    </div>
                                </div> -->
                  <!--/ social -->
                </div>
              </div>
            </div>
            <!--/ right content section -->
          </div>
        </section>
        <!-- / account setting page -->
      </div>
    </main-layout>
  </section>
</template>

<script>
import MainLayout from "../MainLayout.vue";
import VueUploadAvatar from "../../VueUploadAvatar.vue";
import Treeselect from "@riophae/vue-treeselect";
import feather from "feather-icons";
// import { LinkIcon } from 'vue-feather-icons'

export default {
  name: "GeneralSettings",
    metaInfo: {
        titleTemplate: 'UCLan | General Setting'
    },
  components: {
    MainLayout,
    VueUploadAvatar,
    Treeselect,
    // LinkIcon
  },
  data: () => {
    return {
      form: {
        general: {
          id: "",
          company_name: "",
          admin_email: "",
          primary_phone: "",
          agent_mobile_country_id: null,
          contact_person: "",
          contact_person_designation: "",
          contact_number_country_id: null,
          contact_number: "",
          contact_email: "",
        },
        headquarter: {
          id: "",
          address_one: "",
          address_two: "",
          state: "",
          city: "",
          zipcode: "",
          country_id: null,
          agent_company_id: null,
        },
        contact_person: {
          id: "",
          contact_person: "",
          contact_person_designation: "",
          contact_number_country_id: null,
          contact_number: "",
          contact_email: "",
          website: "",
          twitter: "",
          facebook: "",
          linkedin: "",
          instagram: "",
          agent_company_id: null,
        },
      },
      info: {
        company: {
          logo_url: null,
        },
      },
      countryList: [],
      countrySelectList: [],
      active_tab: "general",
    };
  },
  methods: {
    upload_company_logo: function (form_data) {
      var ref = this;
      ref.ajax_setup("agent_api_token");
      var jq = ref.jq();
      var url = ref.url("api/v1/agent/ajax/upload_agent_company_logo");

      ref.wait_me(".company-logo-uploader");

      jq.ajax({
        url: url,
        data: form_data,
        processData: false,
        contentType: false,
        type: "POST",
        success: (res) => {
          ref.info.company.logo_url = res.data.logo_url;
          ref.$store.commit('siteInfo', {
              key: 'agent_company_logo_url',
              val: res.data.logo_url
          });
          ref.$store.commit("siteInfo", {
            key: "company",
            val: ref.info.company,
          });

          ref.alert(res.msg);
        },
      })
        .fail((err) => ref.alert(ref.err_msg(err), "error"))
        .always(() => ref.wait_me(".company-logo-uploader", "hide"));
    },
    update_agent_general_data: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        ref.wait_me("#account-vertical-general");
        var res = await jq.post(
          ref.url("api/v1/agent/ajax/update_agency_general_data"),
          ref.form.general
        );
        ref.alert(res.msg);
        this.get_agent_data();
        ref.onChangeTab("headquarter");
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me("#account-vertical-general", "hide");
      }
    },
    agent_headquarter_data: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        ref.wait_me(".wait_me_loader");
        var res = await jq.post(
          ref.url("api/v1/agent/ajax/update_agency_headquarter_data"),
          ref.form.headquarter
        );
        ref.alert(res.msg);
        this.get_agent_data();
        ref.onChangeTab("contact_person");
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_loader", "hide");
      }
    },
    update_agent_contact_person_data: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        ref.wait_me(".wait_me_loader");
        var res = await jq.post(
          ref.url("api/v1/agent/ajax/update_agency_contact_data_uclan_agent"),
          ref.form.general
        );
        ref.alert(res.msg);
        // this.get_agent_data();
        // ref.onChangeTab('social');
        ref.$router.push("/dashboard");
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_loader", "hide");
      }
    },
    agent_web_and_social_data: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        // ref.wait_me('#wait_me_loader');
        var res = await jq.post(
          ref.url("api/v1/agent/ajax/update_agency_web_and_social_data"),
          ref.form.contact_person
        );
        ref.alert(res.msg);
        ref.$router.push("/dashboard");
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        // ref.wait_me('#wait_me', 'hide');
      }
    },
    onChangeTab: function (value) {
      var ref = this;
      ref.active_tab = value;
    },
    reset_general_form: function () {
      var ref = this;
      ref.form.general.company_name = "";
      ref.form.general.admin_email = "";
      ref.form.general.primary_phone = "";
    },
    /* set_general_form_data: function(){
            var ref=this;
            ref.form.general.id = this.cn(this.$store.state, 'site.auth_user.agent.id', null);
            ref.form.general.company_name = this.cn(this.$store.state, 'site.auth_user.agent.name', null);
            ref.form.general.admin_email = this.cn(this.$store.state, 'site.auth_user.agent.email', null);
            ref.form.general.primary_phone = this.cn(this.$store.state, 'site.auth_user.agent.agent_mobile', null);
            ref.form.headquarter.agent_company_id = this.cn(this.$store.state, 'site.auth_user.agent.id', null);
            ref.form.contact_person.agent_company_id = this.cn(this.$store.state, 'site.auth_user.agent.id', null);
        }, */
    get_agent_data: async function () {
      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v1/agent/ajax/get_agency_detials");

      try {
        // ref.wait_me('#wait_me_loader');

        var res = await jq.get(url);
        ref.form.general.id = res.data.agentCompany.id;
        ref.form.general.company_name = res.data.agentCompany.name;
        ref.form.general.agent_code = res.data.agentCompany.agent_code;
        ref.form.general.admin_email = res.data.agentCompany.email;
        ref.form.general.agent_mobile_country_id =
          res.data.agentCompany.agent_mobile_country_id;
        ref.form.general.primary_phone = res.data.agentCompany.agent_mobile;

        ref.info.company.logo_url = res.data.logo_url;

        if (res.data.agentCompany.office) {
          ref.form.headquarter = res.data.agentCompany.office;
        }

        ref.form.general.contact_person = res.data.agentCompany.contact_person;
        ref.form.general.contact_person_designation =
          res.data.agentCompany.contact_person_designation;
        ref.form.general.contact_number_country_id =
          res.data.agentCompany.contact_number_country_id;
        ref.form.general.contact_number = res.data.agentCompany.contact_number;
        ref.form.general.contact_email = res.data.agentCompany.contact_email;

        // if(res.data.agentCompany.profile){
        //     ref.form.contact_person = res.data.agentCompany.profile
        // }
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_loader", "hide");
      }
    },
    get_country_data: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        ref.wait_me(".wait_me_loader");
        var res = await jq.get(ref.url("api/v1/agent/ajax/get_country_list"));
        ref.countryList = res.data.country;
        ref.countrySelectList = res.data.country.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_loader", "hide");
      }
    },
  },
  computed: {
    store_auth_user: function () {
      return this.cn(this.$store.state, "site.auth_user", null);
    },
    store_countries: function () {
      return this.$store.state.site.countries;
    },
    treeselect_countries: function () {
      return this.store_countries.map((each) => {
        return {
          id: each.id,
          label: each.name,
        };
      });
    },
    treeselect_countries_calling_code: function () {
      return this.store_countries.map((each) => {
        return {
          id: each.id,
          label: each.name,
        };
      });
    },
  },
  mounted: function () {
    feather.replace();
    // var jq=this.jq();
    // jq('body').addClass('blank-page');
    //this.set_general_form_data();
  },
  created() {
    this.setup_urls();
    //this.set_general_form_data();
    this.get_country_data();
    this.get_agent_data();
  },
};
</script>

<style>
.county_calling_code_select {
  border: none !important;
  width: 120px;
}
</style>
